// External packages
import * as React from "react"
import { Paragraph, Box, Heading, Flex, Text, Image } from "theme-ui"
import { keyframes } from "@emotion/react"
import { useLocation } from "@reach/router"
import { useProducts } from "medusa-react"
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"
import useDetectScroll from "@smakss/react-scroll-direction"

// Components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import {
  UiCategoryGrid,
  VisualItem,
  handlePageChange,
} from "../templates/medusa/CategoryTemplate"
import { UiProgress } from "../components/v2/ui/Progress"
import { Button } from "../components/v2/Button"
import { Link } from "../components/v2/Link"

// Contexts
import { useStore } from "../context/NewStoreContext"

// Utilities
import { getMinimumPrice } from "../components/v2/utils/product/getMinimumPrice"
import { getMaximumPrice } from "../components/v2/utils/product/getMaximumPrice"
import { generateGatsbyImageData } from "../shopstory/gatsby/imageUtils"

// Services
import { trackSearchProductClicked } from "../services/analytics"

// Hooks
import usePagination from "../hooks/v2/usePagination"
import {
  useSearch,
  useSearchNoResultsSuggestedProducts,
} from "../hooks/luigis-box/queries"
import { getDownCollectionTitle } from "../components/v2/utils/product/getTitle"

const CONTENT_PER_PAGE = 32

export const getSearchResultTitle = (p: PricedProduct) => {
  if (!p) {
    return ""
  }

  const category = p.categories?.filter((c) => c.parent_category_id)?.[0]

  const fabricLabel = category?.metadata?.fabric ?? ""
  const originalLabel = p.type?.value ?? ""

  if (!originalLabel) {
    return p.title
  }

  let title = fabricLabel
    ? `${fabricLabel} ${
        originalLabel.includes("shoes")
          ? originalLabel
          : originalLabel.charAt(0).toLowerCase() + originalLabel.slice(1)
      }`
    : originalLabel

  if (
    p.categories?.some(
      (cat) =>
        cat.handle === "blankets" ||
        cat.handle === "the-colours-of-le-corbusier"
    )
  ) {
    title = `${originalLabel} blanket`
  }

  if (p.variants?.length) {
    if (
      p.variants.some(
        (v) => v.sku?.startsWith("TD-") || v.sku?.startsWith("DN-")
      )
    ) {
      const downCollection = p.variants.find(
        (v) => v.sku?.startsWith("TD-") || v.sku?.startsWith("DN-")
      )

      title = getDownCollectionTitle(downCollection.sku)
    }
  }

  return title
}

const SearchPage = () => {
  const { cart } = useStore()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const query = searchParams.get("q")
  const currentPage = searchParams.get("page")
    ? parseInt(searchParams.get("page"))
    : 1
  const [displayedProductsOptions, setDisplayedProductsOptions] =
    React.useState<{
      initialQuery: string
      products: PricedProduct[]
    }>({
      initialQuery: query,
      products: [],
    })
  const [tabId, setTabId] = React.useState(0)
  const [recentlyViewedProducts, setRecentlyViewedProducts] = React.useState([])
  const [totalHits, setTotalHits] = React.useState<number>(0)
  const { data, isSuccess, isLoading, isRefetching } = useSearch(
    query,
    CONTENT_PER_PAGE,
    currentPage,
    cart
  )
  const { scrollDir, scrollPosition } = useDetectScroll()
  const categoryStickyBarRef = React.useRef(null)
  const { data: noResultsSuggestedProducts } =
    useSearchNoResultsSuggestedProducts(cart?.region_id, {
      enabled: Boolean(cart?.region_id),
      keepPreviousData: true,
    })
  const isProductsQueryEnabled = isSuccess && totalHits > 0 ? true : false

  React.useEffect(() => {
    if (query !== displayedProductsOptions.initialQuery) {
      setDisplayedProductsOptions({
        ...displayedProductsOptions,
        initialQuery: query,
        products: [],
      })
    }

    if (isLoading || isRefetching) {
      return
    }

    if (!data?.products?.length) {
      setDisplayedProductsOptions({
        ...displayedProductsOptions,
        products: [],
      })

      return
    }

    if (currentPage === 1) {
      setDisplayedProductsOptions({
        ...displayedProductsOptions,
        products: data.products,
      })
    } else {
      const displayedProductIds = displayedProductsOptions.products.map(
        (p) => p.id
      )
      const newProducts = data.products.filter(
        (p) => !displayedProductIds.includes(p.id)
      )

      setDisplayedProductsOptions({
        ...displayedProductsOptions,
        products: [...displayedProductsOptions.products, ...newProducts],
      })
    }
  }, [data, query])

  React.useEffect(() => {
    if (isSuccess && data?.total_hits) {
      setTotalHits(data.total_hits)

      if (data.total_hits === 0) {
        const recentlyViewed = JSON.parse(
          localStorage.getItem("tekla::recently_viewed")
        )

        if (recentlyViewed && recentlyViewed.length) {
          setRecentlyViewedProducts(recentlyViewed.slice(0, 4))
        }
      }
    }
  }, [isSuccess, data?.total_hits])

  const { nextPage, page, totalPages, prevPage } = usePagination({
    contentPerPage: CONTENT_PER_PAGE,
    count: totalHits,
    currentPage: currentPage,
  })

  const showing =
    CONTENT_PER_PAGE * (currentPage - 1) + (data?.products?.length || 0)

  if (!query) {
    return (
      <Layout almostWhite>
        <SEO title={"Product Search"} />
        <Box sx={{ paddingBlockStart: [3, 21], paddingBlockEnd: [8, 18] }}>
          <Box sx={{ paddingInline: 4, marginBlockEnd: 3 }}>
            <Heading as="h1" sx={{ fontSize: "sm" }}>
              Please enter a search term
            </Heading>
          </Box>
        </Box>
      </Layout>
    )
  }

  const fadeIn = keyframes({
    from: { opacity: 0.2 },
    to: { opacity: 1 },
  })

  return (
    <Layout almostWhite>
      <SEO title={"Product Search"} />
      <Box
        sx={{ paddingBlockStart: [3, 21], paddingBlockEnd: [8, 18] }}
        className={showing < 1 ? "luigisbox-no-results" : ""}
      >
        <Box sx={{ paddingInline: 4, marginBlockEnd: [0, 3] }}>
          <Heading as="h1" sx={{ fontSize: "md" }}>
            {showing > 0
              ? `Search results for “${query}”`
              : isSuccess && totalHits === 0
              ? `No results for “${query}”`
              : null}
          </Heading>
        </Box>
        {page > 1 &&
          showing > 0 &&
          displayedProductsOptions?.products?.length < showing && (
            <Box sx={{ width: 53, textAlign: "center", marginInline: "auto" }}>
              <Button
                variant="ghost"
                href={`${location.pathname}?page=${page - 1}`}
                sx={{ marginBlockStart: 6 }}
                onClick={(e) => {
                  e.preventDefault()
                  prevPage()
                  handlePageChange(page - 1)
                }}
              >
                Show previous
              </Button>
            </Box>
          )}
        {showing > 0 ? (
          <Flex
            ref={categoryStickyBarRef}
            sx={{
              height: 13,
              backgroundColor: "grayscale.100",
              position: ["initial", "sticky"],
              top: "-1px",
              zIndex: "categoryBar",
              fontSize: "sm",
              alignItems: "center",
              justifyContent: "space-between",
              transform:
                scrollDir === "down"
                  ? "translateY(0)"
                  : scrollDir === "up" &&
                    scrollPosition.top >
                      categoryStickyBarRef?.current?.offsetTop
                  ? ["translateY(0)", "translateY(100%)"]
                  : null,
              transition: "transform .25s",
              paddingInline: 4,
              marginBlockEnd: 3,
            }}
          >
            <Text
              sx={{ color: "grayscale.600" }}
              className="luigisbox-showing-count"
            >
              {displayedProductsOptions?.products?.length} products
            </Text>
          </Flex>
        ) : null}
        {!isSuccess ? (
          <Flex sx={{ justifyContent: "center", marginBlockStart: 20 }}>
            <Flex
              className="luigisbox-loading-spinner"
              sx={{
                justifyContent: "center",
                div: {
                  width: 1,
                  height: 1,
                  borderRadius: "100%",
                  backgroundColor: "currentColor",
                  marginInline: 1,
                  marginBlockEnd: 10,
                  opacity: 0.2,
                },
              }}
            >
              <Box
                sx={{
                  animation: `${fadeIn} .6s linear infinite alternate`,
                }}
              />
              <Box
                sx={{
                  animation: `${fadeIn} .6s linear .15s infinite alternate`,
                }}
              />
              <Box
                sx={{
                  animation: `${fadeIn} .6s linear .30s infinite alternate`,
                }}
              />
            </Flex>
          </Flex>
        ) : null}
        {displayedProductsOptions?.products?.length > 0 ? (
          <UiCategoryGrid>
            {displayedProductsOptions?.products?.map((item) => (
              <VisualItem
                key={item.id}
                title={getSearchResultTitle(item)}
                handle={`/product/${item.handle}`}
                image={generateGatsbyImageData({
                  imageUrl: item?.thumbnail?.startsWith("https:")
                    ? item.thumbnail
                    : `https:${item.thumbnail}`,
                })}
                priceMin={getMinimumPrice(item.variants)}
                priceMax={getMaximumPrice(item.variants)}
                onClick={() =>
                  trackSearchProductClicked({
                    query: query,
                    product: item,
                  })
                }
                wrapperClassName="luigisbox-search-result-item"
                titleClassName="luigisbox-search-result-item-title"
                priceClassName="luigisbox-search-result-item-price"
              />
            ))}
          </UiCategoryGrid>
        ) : null}
        <Box
          sx={{
            maxWidth: "100%",
            marginInline: "auto",
            marginBlockStart: showing > 0 && [13, 18],
          }}
        >
          {showing > 0 && displayedProductsOptions.products?.length ? (
            <Paragraph
              sx={{
                fontSize: "sm",
                textAlign: "center",
                marginBlockEnd: 6,
              }}
              className="luigisbox-showing-count-pagination"
            >
              {!isSuccess ? (
                <Flex sx={{ justifyContent: "center", marginBlockStart: 20 }}>
                  <Flex
                    sx={{
                      justifyContent: "center",
                      div: {
                        width: 1,
                        height: 1,
                        borderRadius: "100%",
                        backgroundColor: "currentColor",
                        marginInline: 1,
                        marginBlockEnd: 10,
                        opacity: 0.2,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        animation: `${fadeIn} .6s linear infinite alternate`,
                      }}
                    />
                    <Box
                      sx={{
                        animation: `${fadeIn} .6s linear .15s infinite alternate`,
                      }}
                    />
                    <Box
                      sx={{
                        animation: `${fadeIn} .6s linear .30s infinite alternate`,
                      }}
                    />
                  </Flex>
                </Flex>
              ) : (
                `Showing ${showing} of ${totalHits} products`
              )}
            </Paragraph>
          ) : null}
          {showing < 1 && isSuccess && totalHits === 0 ? (
            <>
              <Flex
                sx={{
                  flexDirection: "column",
                  gap: 4,
                  fontSize: "sm",
                  paddingInline: 4,
                  marginBlockStart: 8,
                }}
              >
                <Paragraph>Explore:</Paragraph>
                <Paragraph>
                  <Link href="/all-products">All products</Link>
                </Paragraph>
                <Paragraph>
                  <Link href="/bestsellers">Bestsellers</Link>
                </Paragraph>
                <Paragraph>
                  <Link href="/new-in">New in</Link>
                </Paragraph>
              </Flex>
              <Box
                sx={{
                  backgroundColor: "grayscale.100",
                  paddingInline: 4,
                  paddingBlockStart: 10,
                }}
              >
                <Flex sx={{ gap: 5 }}>
                  {noResultsSuggestedProducts?.length ? (
                    <Link
                      onClick={() => setTabId(0)}
                      sx={{
                        fontSize: ["base", "md"],
                        color: tabId !== 0 ? "grayscale.600" : "primary",
                        transition: "color .2s",
                        marginBlockEnd: 6,
                      }}
                    >
                      Suggested products
                    </Link>
                  ) : null}
                  {recentlyViewedProducts?.length > 1 && (
                    <Link
                      onClick={() =>
                        setTabId(noResultsSuggestedProducts?.length ? 1 : 0)
                      }
                      sx={{
                        fontSize: ["base", "md"],
                        color: tabId === 1 ? "primary" : "grayscale.600",
                        transition: "color .2s",
                        marginBlockEnd: 6,
                      }}
                    >
                      Recently viewed
                    </Link>
                  )}
                </Flex>
                <Box>
                  <Box
                    sx={{
                      display: tabId === 0 ? "block" : "none",
                      overflowX: ["scroll", "unset"],
                      scrollbarWidth: "none",
                      marginInline: -4,

                      "::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {noResultsSuggestedProducts?.length ? (
                      <Flex
                        sx={{
                          gap: [4, 0],
                          overflowX: ["scroll", "unset"],
                          marginInline: [-2, 2],
                          scrollbarWidth: "none",

                          "::-webkit-scrollbar": {
                            display: "none",
                          },

                          "&::before, &::after": {
                            content: "''",
                            display: ["block", "none"],
                            flex: "0 0 8px",
                          },
                        }}
                      >
                        {noResultsSuggestedProducts.map((p) => {
                          const imageData = generateGatsbyImageData({
                            imageUrl: p?.thumbnail?.startsWith("https:")
                              ? p.thumbnail
                              : `https:${p.thumbnail}`,
                          })

                          return (
                            <Box
                              key={p.id}
                              sx={{
                                flexBasis: [
                                  "calc((((100% - 16px) - 16px) - 4px) / 1.5)",
                                  "25%",
                                ],
                                flexGrow: 0,
                                flexShrink: 0,
                                paddingInline: [0, 2],
                              }}
                            >
                              <Link
                                to={`/product/${p.handle}`}
                                sx={{ display: "block" }}
                              >
                                {imageData && (
                                  <Box sx={{ height: "100%" }}>
                                    <picture>
                                      {imageData.images.sources.map(
                                        (source, index) => (
                                          <source
                                            key={index}
                                            type={source.type}
                                            srcSet={source.srcSet}
                                            sizes={source.sizes}
                                          />
                                        )
                                      )}
                                      <Image
                                        width={360}
                                        height={450}
                                        sizes={imageData.images.fallback.sizes}
                                        decoding="async"
                                        loading="lazy"
                                        src={imageData.images.fallback.src}
                                        srcSet={
                                          imageData.images.fallback.srcSet
                                        }
                                        alt={p.title}
                                        sx={{
                                          display: "block",
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          transform: "translateZ(0)",
                                        }}
                                      />
                                    </picture>
                                  </Box>
                                )}
                              </Link>
                              <Link
                                to={`/product/${p.handle}`}
                                sx={{ fontSize: "sm", marginBlockStart: 2 }}
                              >
                                {p.title}
                              </Link>
                            </Box>
                          )
                        })}
                      </Flex>
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      display: tabId === 1 ? "block" : "none",
                      overflowX: ["scroll", "unset"],
                      scrollbarWidth: "none",
                      marginInline: [-4, 0],

                      "::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {recentlyViewedProducts?.length > 1 && (
                      <Flex
                        sx={{
                          gap: [4, 0],
                          overflowX: ["scroll", "unset"],
                          marginInline: -2,
                          scrollbarWidth: "none",

                          "::-webkit-scrollbar": {
                            display: "none",
                          },

                          "&::before, &::after": {
                            content: "''",
                            display: ["block", "none"],
                            flex: "0 0 8px",
                          },
                        }}
                      >
                        {recentlyViewedProducts?.map((p) => {
                          return (
                            <Box
                              key={p.id}
                              sx={{
                                flexBasis: [
                                  "calc((((100% - 16px) - 16px) - 4px) / 1.5)",
                                  "25%",
                                ],
                                flexGrow: 0,
                                flexShrink: 0,
                                paddingInline: [0, 2],
                              }}
                            >
                              <Link to={p.url} sx={{ display: "block" }}>
                                {p.thumbnail?.gatsbyImageData && (
                                  <Box sx={{ height: "100%" }}>
                                    <picture>
                                      {p.thumbnail.gatsbyImageData.images.sources.map(
                                        (source, index) => (
                                          <source
                                            key={index}
                                            type={source.type}
                                            srcSet={source.srcSet}
                                            sizes={source.sizes}
                                          />
                                        )
                                      )}
                                      <Image
                                        width={
                                          p.thumbnail.gatsbyImageData.width
                                        }
                                        height={
                                          p.thumbnail.gatsbyImageData.height
                                        }
                                        sizes={
                                          p.thumbnail.gatsbyImageData.images
                                            .fallback.sizes
                                        }
                                        decoding="async"
                                        loading="lazy"
                                        src={
                                          p.thumbnail.gatsbyImageData.images
                                            .fallback.src
                                        }
                                        srcSet={
                                          p.thumbnail.gatsbyImageData.images
                                            .fallback.srcSet
                                        }
                                        alt={p.title}
                                        sx={{
                                          display: "block",
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          transform: "translateZ(0)",
                                        }}
                                      />
                                    </picture>
                                  </Box>
                                )}
                              </Link>
                              <Link
                                to={p.url}
                                sx={{ fontSize: "sm", marginBlockStart: 2 }}
                              >
                                {p.title}
                              </Link>
                            </Box>
                          )
                        })}
                      </Flex>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          ) : null}
          <Box sx={{ width: 53, textAlign: "center", marginInline: "auto" }}>
            {showing > 0 && displayedProductsOptions.products?.length ? (
              <UiProgress value={(showing / totalHits) * 100} />
            ) : null}
            {page !== totalPages &&
            showing > 0 &&
            displayedProductsOptions.products?.length ? (
              <Button
                variant="ghost"
                href={`${location.pathname}?page=${page + 1}`}
                sx={{ marginBlockStart: 6 }}
                onClick={(e) => {
                  e.preventDefault()
                  nextPage()
                  handlePageChange(page + 1)
                }}
                disabled={page === totalPages}
                isVisuallyDisabled={page === totalPages}
              >
                Show more
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default SearchPage
