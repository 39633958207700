import { ProductVariant } from "@medusajs/medusa"

export const hasVariantGiftWrapping = (variant: ProductVariant): boolean => {
  const sku = variant?.sku

  if (
    !Boolean(variant) ||
    variant?.product?.is_giftcard ||
    sku === "TT-LS-100x180" ||
    sku === "TT-DS-100x180" ||
    sku === "TT-IS-100x180" ||
    sku.startsWith("DN-HIGH") ||
    sku.startsWith("DN-MEDIUM") ||
    sku.startsWith("DN-LOW") ||
    sku.startsWith("DN-WS") ||
    sku.startsWith("DN-AS") ||
    sku.startsWith("DN-SS") ||
    sku.startsWith("DN-SG")
  ) {
    return false
  }

  return true
}
