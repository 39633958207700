// External packages
import * as React from "react"
import { Box, BoxProps, Flex, Text, Paragraph } from "theme-ui"
import { keyframes } from "@emotion/react"
import * as Collapsible from "@radix-ui/react-collapsible"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "gatsby-link"
import { GatsbyImage } from "gatsby-plugin-image"
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"

// Contexts
import { InterfaceContext } from "../../context/InterfaceContext"
import { useStore } from "../../context/NewStoreContext"

// Hooks
import {
  useIsZendeskLiveChatActive,
  useZendesk,
} from "../../hooks/v2/useZendesk"
import { useDebounce } from "use-debounce"
import { useAutocomplete } from "../../hooks/luigis-box/queries"

// Utilities
import renderNavigationItem from "../../utils/navigation-item"
import { generateGatsbyImageData } from "../../shopstory/gatsby/imageUtils"
import { getMinimumPrice } from "./utils/product/getMinimumPrice"
import { getMaximumPrice } from "./utils/product/getMaximumPrice"
import { trackSearchProductClicked } from "../../services/analytics"
import { getSearchResultTitle } from "../../pages/search"

// Components
import { Link } from "./Link"
import { Icon } from "./ui/Icon"
import { Input } from "../v2/ui/Input"
import { Button } from "../v2/Button"
import {
  UiCategoryGrid,
  VisualItem,
} from "../../../src/templates/medusa/CategoryTemplate"

type MobileNavigationOwnProps = {
  promotionalElement: React.RefObject<HTMLDivElement>
  isOpen: boolean
}

type NavigationItem = {
  id: string
  name: string
  linkTo: string
  highlightLabel: string | null
  highlightColor: string | null
  items: NavigationItem[]
}

type SliderItem = {
  id: string
  name: string
  linkTo: string
  reference: any
}

type MobileNavigationMenu = {
  navigationItems: NavigationItem[]
  sliderItems: SliderItem[]
}

export const MobileNavigation: React.FC<BoxProps & MobileNavigationOwnProps> =
  React.forwardRef(
    (
      {
        promotionalElement,
        isOpen,
        headerOptions,
        setHeaderOptions,
        setLockedMobile,
        setLocked,
        ...rest
      },
      ref
    ) => {
      const [activeSubLevel, setActiveSubLevel] = React.useState<
        string | undefined
      >(undefined)
      const [searchTerm, setSearchTerm] = React.useState("")
      const { cart } = useStore()

      const data = useStaticQuery(query)
      const menu: MobileNavigationMenu = React.useMemo(() => {
        if (!data.main) {
          return
        }

        const dataLocale = data.main?.nodes.find((node) => {
          const localeMatch = "en-US"
          return node.node_locale === localeMatch
        })

        const menu = {
          navigationItems: dataLocale?.items || [],
          sliderItems: dataLocale?.mobileMenuSliderItems || [],
        }

        return menu
      }, [])

      const { isPromotionalVisible } = React.useContext(InterfaceContext)

      const [isPromotionalInViewport, setIsPromotionalInViewport] =
        React.useState(false)

      React.useMemo(() => {
        if (typeof window !== "undefined") {
          if (window.scrollY < 35) {
            setTimeout(() => {
              setIsPromotionalInViewport(true)
            }, 200)
          } else {
            setIsPromotionalInViewport(false)
          }
        }
      }, [isOpen])

      const searchRef = React.useRef(null)

      const [debouncedSearchTerm] = useDebounce(searchTerm, 100)
      const { data: autocompleteData, isFetching } = useAutocomplete(
        debouncedSearchTerm,
        cart,
        12
      )

      const isLoading = isFetching && Boolean(debouncedSearchTerm)

      // highlightLabel and highlightColor are added to Bedroom, Sleepwear->Limited editions, Sleepwear->Limited editions->Stussy/Tekla

      return (
        <Box
          {...rest}
          ref={ref}
          sx={{
            position: "fixed",
            top: "52px",
            width: "100%",
            height: "calc(100dvh - 52px)",
            opacity: isOpen ? 1 : 0,
            visibility: isOpen ? "visible" : "hidden",
            backgroundColor: "grayscale.100",
            fontSize: "sm",
            transition: "opacity 0.2s, visibility 0.2s",
            paddingInline: 4,
            zIndex: "mobileMenu",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "100%",
              overflowX: "hidden",
              paddingBlockStart:
                isPromotionalVisible && isPromotionalInViewport && "34px",
              paddingInline: 4,
              marginInline: -4,
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                width: "100%",
                backgroundColor: "grayscale.100",
                zIndex: "mobileMenuSearchInput",
                paddingBlock: 4,
                "> form": {
                  marginBlockEnd: 0,
                },
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault()

                  if (searchTerm) {
                    setHeaderOptions({
                      ...headerOptions,
                      headerHasBackground: false,
                      offcanvasIsOpen: false,
                    })
                    navigate(`/search?q=${searchTerm}`, {
                      replace: true,
                    })
                    setLocked(false)
                    setLockedMobile(false)
                  }
                }}
              >
                <Input
                  placeholder="Search"
                  iconName="search"
                  hasReset
                  className="luigisbox-search-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onFocus={() => {
                    searchRef.current.dataset.visible = "true"
                  }}
                  onBlur={(event) => {
                    if (event.target.value.length < 1) {
                      searchRef.current.dataset.visible = "false"
                    }
                  }}
                  onResetClick={(e) => {
                    searchRef.current.dataset.visible = "false"
                  }}
                  inputSx={{ backgroundColor: "transparent" }}
                />
              </form>
            </Box>
            <Box as="ul" sx={{ listStyleType: "none" }}>
              {menu?.navigationItems.map((item, i) =>
                item.items?.length > 0 ? (
                  <Box as="li" key={i}>
                    <Box
                      key={i}
                      as="button"
                      onClick={() => setActiveSubLevel(item.name)}
                      sx={{
                        color: "primary",
                        width: "100%",
                        height: 15,
                        appearance: "none",
                        textAlign: "left",
                        backgroundColor: "transparent",
                        border: 0,
                        borderBlock: "1px solid",
                        borderBlockColor: "grayscale.300",
                        padding: 0,
                        marginBlockStart: "-1px",
                      }}
                    >
                      {item.name}
                      {Boolean(item.highlightLabel) && (
                        <Text
                          sx={{
                            fontSize: "xxs",
                            color:
                              Boolean(item.highlightColor) &&
                              item.highlightColor,
                            position: "relative",
                            top: "-6px",
                            left: "2px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.highlightLabel}
                        </Text>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box as="li" key={i}>
                    <Box
                      sx={{
                        width: "100%",
                        height: 15,
                        appearance: "none",
                        textAlign: "left",
                        backgroundColor: "transparent",
                        border: 0,
                        borderBlock: "1px solid",
                        borderBlockColor: "grayscale.300",
                        padding: 0,
                        marginBlockStart: "-1px",
                        a: {
                          display: "block",
                          width: "100%",
                          lineHeight: "60px",
                          textDecoration: "none",
                          color: "primary",
                        },
                      }}
                    >
                      {renderNavigationItem({
                        name: item.name,
                        item: item,
                        locale: "en-US",
                        menuType: "mobile",
                        highlightLabel: item.highlightLabel,
                        highlightColor: item.highlightColor,
                      })}
                    </Box>
                  </Box>
                )
              )}
            </Box>
            {menu.navigationItems
              ?.filter((i) => i.items?.length > 0)
              .map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: "calc(100% - 52px)",
                    position: "fixed",
                    top: "52px",
                    left: 0,
                    overflowY: "scroll",
                    backgroundColor: "grayscale.100",
                    zIndex: 1,
                    paddingInline: 4,
                    paddingBlockStart:
                      isPromotionalVisible && isPromotionalInViewport
                        ? "121px"
                        : "87px",
                    opacity: activeSubLevel === item.name ? 1 : 0,
                    visibility:
                      activeSubLevel === item.name ? "visible" : "hidden",
                  }}
                >
                  <Box as="ul" sx={{ listStyleType: "none" }}>
                    <Box as="li">
                      <Box
                        as="button"
                        onClick={() => setActiveSubLevel(undefined)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                          width: "100%",
                          height: 15,
                          appearance: "none",
                          textAlign: "left",
                          backgroundColor: "transparent",
                          border: 0,
                          padding: 0,
                        }}
                      >
                        <Box sx={{ svg: { display: "block" } }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="21"
                            fill="none"
                          >
                            <path
                              fill="#181818"
                              d="M.646 10.146a.5.5 0 0 0 0 .708l3.182 3.182a.5.5 0 1 0 .708-.708L1.707 10.5l2.829-2.828a.5.5 0 1 0-.708-.708L.646 10.146ZM17 10H1v1h16v-1Z"
                            />
                          </svg>
                        </Box>
                        <Text sx={{ color: "primary" }}>{item.name}</Text>
                        {Boolean(item.highlightLabel) && (
                          <Text
                            sx={{
                              fontSize: "xxs",
                              color:
                                Boolean(item.highlightColor) &&
                                item.highlightColor,
                              position: "relative",
                              top: "-6px",
                              left: "-10px",
                              fontWeight: "bold",
                            }}
                          >
                            {item.highlightLabel}
                          </Text>
                        )}
                      </Box>
                    </Box>
                    {item.items.map((subItem, i) =>
                      subItem?.items?.length > 0 ? (
                        <Box as="li" key={i}>
                          <Collapsible.Root>
                            <Collapsible.Trigger asChild>
                              <Flex
                                as="button"
                                sx={{
                                  color: "primary",
                                  width: "100%",
                                  height: 15,
                                  textAlign: "left",
                                  backgroundColor: "transparent",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: 0,
                                  padding: 0,
                                  borderBlock: "1px solid",
                                  borderBlockColor: "grayscale.300",
                                  marginBlockStart: "-1px",
                                  "::after": {
                                    fontSize: "md",
                                  },
                                  "&[data-state='open']": {
                                    borderBottom: "0 !important",
                                  },
                                  "&[data-state='open']::after": {
                                    content: "'–'",
                                  },
                                  "&[data-state='closed']::after": {
                                    content: "'+'",
                                  },
                                }}
                              >
                                <span>{subItem.name}</span>
                                {Boolean(subItem.highlightLabel) && (
                                  <Text
                                    sx={{
                                      fontSize: "xxs",
                                      color:
                                        Boolean(subItem.highlightColor) &&
                                        subItem.highlightColor,
                                      position: "relative",
                                      top: "-6px",
                                      left: "2px",
                                      marginInlineEnd: "auto",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {subItem.highlightLabel}
                                  </Text>
                                )}
                              </Flex>
                            </Collapsible.Trigger>
                            <Collapsible.Content>
                              <Flex
                                sx={{
                                  flexDirection: "column",
                                  gap: 5,
                                  marginBlockStart: 2,
                                }}
                              >
                                <Flex
                                  as="ul"
                                  sx={{
                                    flexDirection: "column",
                                    gap: 4,
                                    listStyleType: "none",
                                    marginBlockEnd: "24px !important",
                                  }}
                                >
                                  {subItem?.items?.map((subSubItem, i) => (
                                    <Box
                                      as="li"
                                      key={i}
                                      sx={{
                                        a: {
                                          textDecoration: "none",
                                          color: "primary",
                                        },
                                      }}
                                    >
                                      {renderNavigationItem({
                                        name: subSubItem.name,
                                        item: subSubItem,
                                        locale: "en-US",
                                        menuType: "mobile",
                                        highlightLabel:
                                          subSubItem.highlightLabel,
                                        highlightColor:
                                          subSubItem.highlightColor,
                                        internalLinkOnClick: () => {
                                          if (
                                            setHeaderOptions &&
                                            setLockedMobile
                                          ) {
                                            setHeaderOptions({
                                              ...headerOptions,
                                              offcanvasIsOpen: false,
                                            })
                                            setLockedMobile(false)
                                          }
                                        },
                                      })}
                                    </Box>
                                  ))}
                                </Flex>
                              </Flex>
                            </Collapsible.Content>
                          </Collapsible.Root>
                        </Box>
                      ) : (
                        <Box as="li" key={i}>
                          <Box
                            sx={{
                              width: "100%",
                              height: 15,
                              appearance: "none",
                              textAlign: "left",
                              backgroundColor: "transparent",
                              border: 0,
                              borderBlock: "1px solid",
                              borderBlockColor: "grayscale.300",
                              padding: 0,
                              marginBlockStart: "-1px",
                              a: {
                                color: "primary",
                                display: "flex",
                                height: 15,
                                alignItems: "center",
                                textDecoration: "none",
                              },
                            }}
                          >
                            {renderNavigationItem({
                              name: subItem.name,
                              item: subItem,
                              locale: "en-US",
                              menuType: "mobile",
                              highlightLabel: subItem.highlightLabel,
                              highlightColor: subItem.highlightColor,
                            })}
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              ))}
            {menu.sliderItems?.length > 0 && (
              <ContentfulWidget items={menu.sliderItems} />
            )}
            <Footer />
            <Search
              ref={searchRef}
              isLoading={isLoading}
              debouncedSearchTerm={debouncedSearchTerm}
              isPromotionalInViewport={isPromotionalInViewport}
              promotionalElement={promotionalElement}
              data={autocompleteData}
            />
          </Box>
        </Box>
      )
    }
  )

type ContentfulWidgetProps = BoxProps & {
  items: SliderItem[]
}

const ContentfulWidget: React.FC<ContentfulWidgetProps> = React.forwardRef(
  ({ items, ...rest }, ref) => {
    return (
      <Box
        {...rest}
        ref={ref}
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          marginBlockStart: 4,
          marginBlockEnd: 10,
          marginInline: -4,
          paddingInline: 4,
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box sx={{ display: "inline-flex", gap: 4 }}>
          {items?.map((item, index) => (
            <Link to={item.linkTo} key={index}>
              <Box sx={{ width: "248px", img: { display: "block" } }}>
                <GatsbyImage
                  image={item.reference?.asset?.gatsbyImageData}
                  alt={item.name}
                />
                <Paragraph sx={{ fontSize: "sm", marginTop: 2 }}>
                  {item.name}
                </Paragraph>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    )
  }
)

const Footer = () => {
  const offcanvasFooterRef = React.useRef(null)
  const { toggleRegionOpen } = React.useContext(InterfaceContext)
  const { cart } = useStore()
  const { isActive: isLiveChatActive } = useIsZendeskLiveChatActive()
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()

  return (
    <>
      <Flex
        ref={offcanvasFooterRef}
        sx={{
          fontSize: "sm",
          gap: 3,
          flexDirection: "column",
          width: "100%",
          backgroundColor: "grayscale.100",
          marginBlockEnd: 4,
        }}
      >
        <Link to="/account/sign-in">
          <Flex sx={{ gap: 2, alignItems: "center" }}>
            <Icon name="user" />
            <Text>Sign in</Text>
          </Flex>
        </Link>
        <Link to="/wishlist">
          <Flex
            sx={{
              gap: 2,
              alignItems: "center",
              svg: {
                width: 6,
                height: 6,
              },
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.35 28.35">
              <path d="M13.88 25.76c-.17 0-.34-.07-.45-.2l-3.97-4.45c-4.81-5.37-6.6-8.63-6.6-12.04s2.6-5.99 5.8-5.99c2.44 0 4.32 1.29 5.26 3.49.94-2.22 2.78-3.49 5.22-3.49 3.23 0 5.96 2.74 5.96 5.99s-1.76 6.5-6.72 12.03l-4.04 4.46c-.11.13-.28.2-.45.2ZM8.66 4.3c-2.53 0-4.58 2.14-4.58 4.78 0 3.07 1.7 6.11 6.29 11.23l3.53 3.95 3.58-3.95c4.73-5.28 6.41-8.22 6.41-11.22 0-2.59-2.17-4.78-4.74-4.78s-4.24 1.74-4.62 4.77c-.04.3-.3.53-.6.53-.31 0-.56-.23-.6-.54-.34-2.98-2.08-4.76-4.65-4.76Z" />
            </svg>
            <Text>Wishlist</Text>
          </Flex>
        </Link>
        <Box
          as="button"
          onClick={() => setIsActivated(true)}
          sx={{
            cursor: "pointer",
            border: 0,
            backgroundColor: "transparent",
            padding: 0,
          }}
        >
          <Flex sx={{ gap: 2, alignItems: "center" }}>
            <Icon name="message-square" />
            <Text sx={{ color: "primary" }}>
              Online support{" "}
              {isLiveChatActive && (
                <Text sx={{ color: "#4C9A62", textTransform: "uppercase" }}>
                  (Live now)
                </Text>
              )}
            </Text>
          </Flex>
        </Box>
        <Flex
          as="button"
          onClick={() => toggleRegionOpen()}
          sx={{
            color: "primary",
            cursor: "pointer",
            gap: 2,
            alignItems: "center",
            border: 0,
            backgroundColor: "transparent",
            padding: 0,
          }}
        >
          <Icon name="globe" />
          <Text>
            Ship to {cart?.region?.name} (
            {cart?.region?.currency_code?.toUpperCase()})
          </Text>
        </Flex>
      </Flex>
      {isActivated && <ZendeskWidget />}
    </>
  )
}

const Search: React.FC<
  React.ComponentPropsWithRef<"div"> & {
    isPromotionalInViewport: boolean
    promotionalElement: React.RefObject<HTMLDivElement>
    debouncedSearchTerm: string
    data: PricedProduct[]
    isLoading: boolean
  }
> = React.forwardRef(
  (
    {
      isPromotionalInViewport,
      promotionalElement,
      debouncedSearchTerm,
      data,
      isLoading,
    },
    ref
  ) => {
    const { isPromotionalVisible } = React.useContext(InterfaceContext)

    const suggestions = data?.slice(0, 3)

    const fadeIn = keyframes({
      from: { opacity: 0.2 },
      to: { opacity: 1 },
    })

    return (
      <Box
        ref={ref}
        data-visible="false"
        sx={{
          display: "none",
          width: "100%",
          minHeight:
            isPromotionalVisible && isPromotionalInViewport
              ? "calc(100% - 106px)"
              : "calc(100% - 72px)",
          position: "absolute",
          top:
            isPromotionalVisible && isPromotionalInViewport ? "106px" : "72px",
          left: 0,
          backgroundColor: "grayscale.100",
          zIndex: "mobileMenuSearchOverlay",
          paddingBlockStart: 6,
          "&[data-visible=true]": {
            display: "block",
          },
        }}
      >
        <Box sx={{ paddingInline: 4 }}>
          {data?.length ? (
            <Paragraph sx={{ color: "grayscale.600", marginBlockEnd: 4 }}>
              Suggestions:
            </Paragraph>
          ) : (
            <Paragraph sx={{ color: "grayscale.600", marginBlockEnd: 4 }}>
              {debouncedSearchTerm && !isLoading ? (
                <p className="luigisbox-no-results-autocomplete">
                  No results found
                </p>
              ) : null}
              {isLoading ? (
                <Flex
                  className="luigisbox-autocomplete-loading-spinner"
                  sx={{
                    justifyContent: "center",
                    div: {
                      width: 1,
                      height: 1,
                      borderRadius: "100%",
                      backgroundColor: "currentColor",
                      marginInline: 1,
                      marginBlockStart: 4,
                      opacity: 0.2,
                    },
                  }}
                >
                  <Box
                    sx={{
                      animation: `${fadeIn} .6s linear infinite alternate`,
                    }}
                  />
                  <Box
                    sx={{
                      animation: `${fadeIn} .6s linear .15s infinite alternate`,
                    }}
                  />
                  <Box
                    sx={{
                      animation: `${fadeIn} .6s linear .30s infinite alternate`,
                    }}
                  />
                </Flex>
              ) : null}
            </Paragraph>
          )}
          <Box
            as="ul"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              listStyleType: "none",
              margin: 0,
            }}
            className="luigisbox-search-suggestions"
          >
            {suggestions?.map((suggestion, i) => (
              <Box as="li" key={i} className="luigisbox-search-suggestion">
                <Link
                  to={suggestion.handle}
                  onClick={() =>
                    trackSearchProductClicked({
                      query: debouncedSearchTerm,
                      product: suggestion,
                    })
                  }
                >
                  {suggestion.title}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
        <UiCategoryGrid
          sx={{
            gridTemplateColumns: "repeat(2, 1fr)",
            paddingBlockStart: 14,
            paddingBlockEnd: 10,
          }}
        >
          {data?.length
            ? data.map((item: PricedProduct, index: number) => (
                <VisualItem
                  key={index}
                  title={getSearchResultTitle(item)}
                  handle={`/product/${item.handle}`}
                  image={generateGatsbyImageData({
                    imageUrl: item?.thumbnail?.startsWith("https:")
                      ? item.thumbnail
                      : `https:${item.thumbnail}`,
                  })}
                  priceMin={getMinimumPrice(item.variants)}
                  priceMax={getMaximumPrice(item.variants)}
                  onClick={() =>
                    trackSearchProductClicked({
                      query: debouncedSearchTerm,
                      product: item,
                    })
                  }
                  size="default"
                  wrapperClassName="luigisbox-search-result-item"
                  titleClassName="luigisbox-search-result-item-title"
                  priceClassName="luigisbox-search-result-item-price"
                />
              ))
            : null}
        </UiCategoryGrid>
        {data?.length ? (
          <Box sx={{ textAlign: "center", marginBlockEnd: 12 }}>
            <Button href={`/search?q=${debouncedSearchTerm}`} variant="ghost">
              View all results
            </Button>
          </Box>
        ) : null}
      </Box>
    )
  }
)

const query = graphql`
  query {
    main: allContentfulNavigationMenu(
      filter: { name: { eq: "Mobile Menu 2024" } }
    ) {
      nodes {
        name
        node_locale
        mobileMenuSliderItems {
          ... on ContentfulNavigationItem {
            id
            name
            linkTo
            reference {
              ... on ContentfulAssetWrapper {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        items {
          ... on ContentfulNavigationItem {
            id
            name
            linkTo
            highlightLabel
            highlightColor
            reference {
              ...SimplePage
              ...SimpleBlogPost
              ...AssetWrapper
            }
            items {
              name
              linkTo
              highlightLabel
              highlightColor
              reference {
                ...SimplePage
                ...SimpleBlogPost
                ...AssetWrapper
              }
              items {
                isHeading
                name
                linkTo
                id
                highlightLabel
                highlightColor
              }
            }
          }
        }
      }
    }
  }
`
