// External packages
import React from "react"
import { Image } from "theme-ui"

// Contexts
import { useStore } from "../../../context/NewStoreContext"

// Utilities
import { hasKlarna } from "../../v2/utils/product/hasKlarna"

// Assets
import KlarnaLogo from "../../../assets/pay-icons/klarna.svg"
import GooglePay from "../../../assets/pay-icons/googlepay.svg"
import Mastercard from "../../../assets/pay-icons/mastercard.svg"
import Paypal from "../../../assets/pay-icons/paypal.svg"
import VISA from "../../../assets/pay-icons/visa.svg"
import ApplePay from "../../../assets/pay-icons/applepay.svg"
import Amex from "../../../assets/pay-icons/amex.svg"
import Giropay from "../../../assets/pay-icons/giropay.svg"
import Ideal from "../../../assets/pay-icons/ideal.svg"
import Bancontact from "../../../assets/pay-icons/bancontact.svg"
import Mobilepay from "../../../assets/pay-icons/mobilepay.svg"
import Alipay from "../../../assets/pay-icons/alipay.svg"
import Unionpay from "../../../assets/pay-icons/unionpay.svg"
import { hasAdyenEnabled } from "../../v2/utils/cart/hasAdyenEnabled"
import type { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types"

const PaymentOptions = ({ options }) => {
  const { cart } = useStore()

  if (hasAdyenEnabled(cart)) {
    const allowedPaymentMethods: PaymentMethodsResponse | undefined =
      cart?.payment_session?.data

    const mappedMethods = allowedPaymentMethods?.paymentMethods?.map(
      (pm) => pm.type
    )

    const schemeBrands = allowedPaymentMethods?.paymentMethods?.find(
      (method) => method.type === "scheme"
    )?.brands

    return (
      <>
        {mappedMethods?.includes("googlepay") && (
          <Image src={GooglePay} sx={{ width: 8 }} />
        )}
        {mappedMethods?.includes("applepay") && (
          <Image src={ApplePay} sx={{ width: 8 }} />
        )}
        {mappedMethods?.includes("paypal") && (
          <Image src={Paypal} sx={{ width: 8 }} />
        )}
        {mappedMethods?.includes("mobilepay") && (
          <Image src={Mobilepay} sx={{ width: 8 }} />
        )}
        {mappedMethods?.includes("klarna") && (
          <Image src={KlarnaLogo} sx={{ width: 8 }} />
        )}
        {mappedMethods?.includes("ideal") && (
          <Image src={Ideal} sx={{ width: 8 }} />
        )}
        {schemeBrands?.includes("visa") && (
          <Image src={VISA} sx={{ width: 8 }} />
        )}
        {schemeBrands?.includes("amex") && (
          <Image src={Amex} sx={{ width: 8 }} />
        )}
        {schemeBrands?.includes("mc") && (
          <Image src={Mastercard} sx={{ width: 8 }} />
        )}
        {schemeBrands?.includes("cup") && (
          <Image src={Unionpay} sx={{ width: 8 }} />
        )}
      </>
    )
  }

  return (
    <>
      {options.includes("stripe") && (
        <Image src={GooglePay} sx={{ width: 8 }} />
      )}
      {options.includes("stripe") && <Image src={ApplePay} sx={{ width: 8 }} />}
      {options.includes("paypal") && <Image src={Paypal} sx={{ width: 8 }} />}
      {options.includes("reepay") && (
        <Image src={Mobilepay} sx={{ width: 8 }} />
      )}
      {options.includes("stripe-alipay") && (
        <Image src={Alipay} sx={{ width: 8 }} />
      )}
      {hasKlarna(cart?.shipping_address?.country_code) && (
        <Image src={KlarnaLogo} sx={{ width: 8 }} />
      )}
      {options.includes("stripe") && <Image src={VISA} sx={{ width: 8 }} />}
      {options.includes("stripe") && <Image src={Amex} sx={{ width: 8 }} />}
      {options.includes("stripe") && (
        <Image src={Mastercard} sx={{ width: 8 }} />
      )}
      {options.includes("stripe-giropay") && (
        <Image src={Giropay} sx={{ width: 8 }} />
      )}
      {options.includes("stripe-ideal") && (
        <Image src={Ideal} sx={{ width: 8 }} />
      )}
      {options.includes("stripe-bancontact") && (
        <Image src={Bancontact} sx={{ width: 8 }} />
      )}
    </>
  )
}

export default PaymentOptions
